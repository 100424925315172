
.search-result-item {
    display: flex;
    align-items: center;
    justify-content:flex-start;
    padding: 10px;
    cursor: pointer;
}

.search-result-item:hover {
    background: #e9e9e9;
}

.search-result-item + .search-result-item {
    border-top: 1px solid #dfdfdf;
}

.search-result-item .image {
    width: 35px;
    height: 35px;
    display: block;
    background-size: cover;
    border-radius: 30px;
    background-color: #d0d0d0;
}

.search-result-item .name {
    color: #000;
    max-width: 170px;
}

.search-result {
    position: absolute;
    display: none;
    width: 275px;
    border-radius: 3px;
    background: #fff;
    border: 1px solid #dfdfdf;
}

.search-result.show {
    display: block;
    z-index: 1;
}

.search-result-item .image {
    min-width: 35px;
    max-width: 35px;
}

.search-result-item .position {
    font-size: 10px;
}

.search-result-item .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}

@media (max-width: 768px) {
    .search {
        width: 100%;
        position: relative;
    }

    .search-result {
        width: 100%;
    }
}