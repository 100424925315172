html, body {
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'pnum' on, 'lnum' on;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    height: 100vh;
}

.f14 {
    font-size: 14px;
}

.fw-normal {
    font-weight: normal;
}

.badge-tag {
    color: #ffffff;
    background-color: #566c78;
    font-weight: unset;
    text-transform: capitalize;
    font-size: 12px;
}

.badge-tag--small {
    color: #ffffff;
    background-color: #566c78;
    font-weight: unset;
    text-transform: capitalize;
    font-size: 10px;
}

.d-flex.loader.container-fluid {
    height: 100%;
}

@media (max-width: 768px) {
    .p-5 {
        padding: 20px 0 !important;
    }
}


.threads {
    display: flex;
    flex-direction: column;
}

.thread {
    display: flex;
    padding: 20px 20px 20px 20px;
}

.thread-user {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    margin-right: 20px;
}

.thread-user img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 50px;
}

.threads {
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    margin-top: 20px;
}

.thread + .thread {
    border-top: 1px solid #ccc;
}

.thread-preview__meta {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
    color: #838383;
}

.thread-preview {
    width: 100%;
}

.thread-preview__title {
    font-weight: bold;
    font-size: 16px;
    margin-top: 6px;
}

.thread-preview__text,
.thread-preview__preview {
    margin-top: 14px;
    font-size: 16px;
}

.thread-buttons__comment {
    font-size: 12px;
}

.thread-buttons {
    margin-top: 18px;
    display: flex;
    justify-content: flex-end;
}

.thread-buttons a {
    color: #333;
}
.comments .ReactCollapse--collapse,
.threads > .ReactCollapse--collapse {
    transition: all .3s;
}

.thread.thread-create {
    border-bottom: 1px solid #c7c7c7;
}

.thread-preview__title a {
    color: #212529;
    text-decoration: none;
}

.thread-buttons__comment a {
    text-decoration: none;
}

.comments {
    margin-top: 20px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    padding: 20px 20px 0px 20px;
}

.comment-head {
    display: flex;
    justify-content: space-between;
}

.comment-user__image {
    width: 30px;
    height: 30px;
}

.comment-user__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50px;
}

.comment-head__date {
    font-size: 12px;
    color: #838383;
}

.comment-user {
    display: flex;
    align-items: center;
}

.comment-user__name {
    margin-left: 10px;
    font-size: 12px;
    color: #838383;
}

.comment-body {
    margin: 5px 0;
}

.comment-buttons__reply {
    font-size: 12px;
    color: #838383;
    cursor: pointer;
}

.comments  .comment {
    margin-bottom: 20px;
}

.comments > .comment:last-child {
    margin-bottom: 0;
}

.comment-children {
    margin-top: 20px;
    padding-left: 20px;
    border-left: 1px solid #ededed;
}

.comments-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blog-back {
    color:  #333;
}

.blog-back:hover {
    color:  #333;
    text-decoration: none;
}