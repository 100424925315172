.regulations-file, .regulations-folder {
    font-size: 14px;
    font-family: 'Noto Sans', sans-serif;
    text-transform: initial !important;
}

.regulations-file svg, .regulations-folder svg {
    margin-right: 5px;
    height: 20px;
}


.regulations-card .card-header {
    display: flex;
    justify-content: space-between;
}