.employees__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.employees__head .card-subtitle {
    font-weight: 400;
}

.employee-item {
    display: flex;
    justify-content: space-between;
}

.employee__position {
    font-size: 12px;
}

.employee__image {
    min-width: 50px;
    min-height: 50px;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    margin-right: 15px;
    background-size: cover;
    background-position: center;
}

.employee__user {
    display: flex;
}

.employee-row + .employee-row {
    margin-top: 20px;
    border-top: 1px solid #e0e0e0;
    padding-top: 20px;
}

.employees-block .card {
    height: 100%;
}

.employees-block__description {
    text-align: center;
}

.employees-block.pt-5 .card-body .row {
    justify-content: center;
}

@media (max-width: 768px) {
    .employee__input {
        width: 50px;
        min-width: 50px;
        margin-left: 20px;
    }
}