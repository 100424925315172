.profile-image {
    width: 100%;
    background-size: cover;
    height: 330px;
    background-position: center;
}

.profile-contacts-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.profile-contacts-row .title {
    font-weight: 700;
    display: flex;
    align-items: center;
}

.profile-contacts-row.bio {
    display: block;
}

.profile-contacts-row.bio .value {
    margin-top: 10px;
}

.profile__awards {
    display: grid;
    grid-template-columns: 18.7% 18.7% 18.7% 18.7% 18.7%;
    text-align: center;
    grid-gap: 10px;
}

.profile__award--image {
    height: 80px;
}

.profile__award--image img {
    width: 80px;
}

.profile__award--title {
    margin-top: 10px;
    font-size: 12px;
}

.profile__award--count {
    position: absolute;
    bottom: 0;
    right: 20px;
    color: #fff;
    font-size: 12px;
    background: #FF5722;
    border-radius: 30px;
    width: 20px;
    height: 20px;
    line-height: 20px;
}

.profile__award--image {
    position: relative;
}

.profile-event {
    position: relative;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
}

.profile-event.profile-event--thank {
    min-height: 75px;
}

.profile-event + .profile-event {
    border-top: 1px solid #dfdfdf;
    margin-top: 30px;
}

.profile-event__body {
    display: flex;
}

.profile-event__body-title {
    font-weight: bold;
}

.profile-event__body-title .fw-normal {
    margin: 0 5px;
}

.profile-event__body-description {
    font-size: 14px;
    margin: 5px 0 0 0;
}

.profile-event__body-image {
    margin-right: 20px;
}

.profile-event__body-image img {
    width: 60px;
    height: 60px;
}

.profile-event__date {
    font-size: 10px;
    color: #878787;
    right: 0;
    bottom: 0;
    position: absolute;
}

.profile-events {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 15px;
}

.profile-event__amount {
    align-self: flex-start;
    display: flex;
    min-width: 70px;
    justify-content: flex-end;
}

.profile-event__body-title {
    word-break: break-all;
}

.profile-event__amount-value {
    margin-right: 5px;
}

.profile-image {
    cursor: pointer;
}

.profile-info {
    display: flex;
    justify-content: space-between;
}

.balance-button__label {
    margin-right: 5px;
}

.profile-info--title h4 span + span {
    margin-left: 5px;
}

.profile-info--title h4 {
    display: flex;
    align-items: center;
}


@media (max-width: 768px) {
    .profile-contacts-row .title {
        margin-bottom: 3px;
    }

    .profile-info--title h4 {
        margin-bottom: 15px;
        display: block;
    }

    .profile-picture__holder {
        margin-bottom: 25px;
    }

    .profile__awards {
        grid-template-columns: 31% 31% 31%;
    }

    .profile-contacts-row {
        flex-direction: column;
    }

    .balance-button > span {
        flex-direction: column;
    }

    .profile-event__amount {
        position: absolute;
        left: 0;
        top: 95px;
        align-items: center;
    }

    .profile-event--thank .profile-event__amount {
        bottom: 0;
        top: initial;
    }

    .profile-event.profile-event--thank .profile-event__body-description {
        margin: 5px 0 30px 0;
    }

    .profile-event__body {
        min-height: 90px;
    }
}

@media (max-width: 380px) {
    .profile__awards {
        grid-template-columns: 49% 49%;
    }
}

.profile-event__body-image img {
    object-fit: contain;
}

.profile__award--image img {
    object-fit: contain;
    height: 100%;
}