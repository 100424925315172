
.shop-product__price {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: red;
}

.shop-product__title {
    background: #fff;
    text-align: center;
    font-size: 14px;
}

.shop-product__img {
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    cursor: pointer;
}

.details-modal {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.details-modal__img {
    width: 250px;
    height: 250px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
}

.details-modal__remains {
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
}

.details-modal__col + .details-modal__col {
    margin-left: 30px;
}

.details-modal__descr {
    justify-self: flex-start;
    font-size: 14px;
}

.details-modal__price {
    color: red;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.details-modal__col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .details-modal.modal-body {
        /* display: block; */
        flex-direction: column;
    }

    .details-modal__col + .details-modal__col {
        margin-left: unset;
        margin-top: 20px;
        text-align: center;
    }

    .details-modal__col {
        align-items: center;
    }


}

.product-images {
    width: 100%;
}

.product-images .slide {
    min-width: 250px;
    height: 250px;
    transition: all .3s;
}

.product-images__holder {
    text-align: center;
    display: flex;
    justify-content: center;
}

.product-images__slider {
    display: flex;
    width: 250px;
    overflow: hidden;
}

.product-images__slider-item {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
}

.product-images__arrows {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}