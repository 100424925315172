.employees-block__image {
    width: 100%;
    background-size: cover;
    height: 330px;
    background-position: center;
}

.employees-block__name {
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
}

.employees-block__position {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    color: #7f7f7f;
}