
.structure-inner{
    background: none;
    border: unset;
}

.structure-users__holder {
    border: 1px solid #ccc;
    border-radius: 3px;
}

.structure-user {
    display: flex;
}

.structure-user__image {
    text-align: center;
    width: 100px;
}

.structure-user__image .image {
    width: 50px;
    height: 50px;
    background-position: center;
    display: block;
    border-radius: 60px;
    margin: 0 auto;
    background-color: #f6f6f6;
    background-size: cover;
    background-repeat: no-repeat;
}

.structure-user {
    padding: 13px;
    color: #3c3c3c;
    text-decoration: none;
}

.structure-user:hover {
    text-decoration: none;
    color: #000;
}

.structure-user__info-title {
    font-size: 18px;
}

.structure-user__info-position {
    font-size: 14px;
    color: #9a9a9a;
    margin-top: 5px;
}

.structure-user-manager + .structure-user{
    border-top: 1px solid #ccc;
}

.structure-items {
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.structure > div:focus {
    outline: none;
}

.rstm-tree-item {
    list-style: none;
}

.structure-user__info-position span.position {
    margin-right: 10px;
}

.rstm-tree-item--active {
    background: #566c78;
}


.rstm-tree-item--focused {
    box-shadow: none;
}

@media (max-width: 768px) {
    .structure-users {
        display: none;
    }

    .structure-item--title {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        background: #f6f6f6;
    }

    .structure-users__holder {
        border: none;
    }

    .structure-user {
        padding: 10px 0;
    }

    .structure-item--title {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        background: #f6f6f6;
        border-bottom: 1px solid #ccc;
    }

    .structure-users__holder {
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
    }

    .structure-user {
        padding: 10px 0;
    }

    .structure-item.structure-item--level-0 > .structure-item--title {
        border-bottom: unset;
    }

    .structure-item.structure-item--level-1 .structure-item--title-text {padding-left: 10px;}
    .structure-item.structure-item--level-2 .structure-item--title-text {padding-left: 20px;}
    .structure-item.structure-item--level-3 .structure-item--title-text {padding-left: 30px;}
    .structure-item.structure-item--level-4 .structure-item--title-text {padding-left: 40px;}
    .structure-item.structure-item--level-5 .structure-item--title-text {padding-left: 50px;}
    .structure-item.structure-item--level-6 .structure-item--title-text {padding-left: 60px;}

    .structure-user__image {
        text-align: center;
        min-width: 75px;
        max-width: 75px;
    }

    .structure-item--active > .structure-item--title {
        background: #e6e5e5;
    }
}