.nav-profile-img {
    width: 50px;
    height: 50px;
    margin: 0 10px;
    border-radius: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

span.navbar-text {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-brand img {
    height: 50px;
}

@media (max-width: 768px) {
    .navbar-nav {
        margin-top: 15px;
    }
}